import * as React from "react"

import Layout from "../components/Layout"
import HeroImageSentiment from "../components/LuxurySentimentPage/HeroImageSentiment"
import SentimentPageText1 from "../components/LuxurySentimentPage/SentimentPageText1"
import SentimentPagePic1 from "../components/LuxurySentimentPage/SentimentPagePic1"
import SentimentPageText2 from "../components/LuxurySentimentPage/SentimentPageText2"
import SentimentPagePic2 from "../components/LuxurySentimentPage/SentimentPagePic2"
import SentimentPageText5 from "../components/LuxurySentimentPage/SentimentPageText5"
import SentimentPagePic4 from "../components/LuxurySentimentPage/SentimentPagePic4"
import SentimentPageText3 from "../components/LuxurySentimentPage/SentimentPageText3"
import SentimentPagePic3 from "../components/LuxurySentimentPage/SentimentPagePic3"
import SentimentPageText4 from "../components/LuxurySentimentPage/SentimentPageText4"
import SentimentPageText6 from "../components/LuxurySentimentPage/SentimentPageText6"
import SentimentPageText7 from "../components/LuxurySentimentPage/SentimentPageText7"
import SentimentPageText8 from "../components/LuxurySentimentPage/SentimentPageText8"
import SentimentPageText9 from "../components/LuxurySentimentPage/SentimentPageText9"
import SentimentPageNewsletter from "../components/LuxurySentimentPage/SentimentPageNewsletter"
import { Helmet } from "react-helmet";


function SentimentPage({ data }) {
  return (
    <Layout>
      <Helmet>
        {/* <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/3EMP1aRMHimHfO5MQ7qoZ8/2659df6bfd8cd6ff4b343ec787a02a93/blog-post.png" /> */}
        <meta property="og:title" content="Luxury Customer Sentiment Analysis" />
        <meta property="og:image" content="https://images.ctfassets.net/x5jqyuhgi4rx/sPbtTz4hb0YyRQgds6oCs/dfc44dfb4608d036ab10214f64396851/TRR_High_Value_Sellers_Social_Listening___Sentiment_Analysis_Report_v1_2.webp" />
        <meta property="og:description" content="How Top Luxury Leaders and Investors Dominate markets" />
        <meta property="og:url" content="https://thefreshkid.com/luxury-customer-sentimentanalysis/" />
      </Helmet>
      <HeroImageSentiment />
      <SentimentPageText1 />
      <SentimentPagePic1 />
      <SentimentPageText2 />
      <SentimentPagePic2 />
      <SentimentPageText5 />
      <SentimentPagePic4 />
      <SentimentPageText3 />
      <SentimentPagePic3 />
      <SentimentPageText4 />
      <SentimentPageText6 />
      <SentimentPageText7 />
      <SentimentPageText8 />
      <SentimentPageText9 />
      <SentimentPageNewsletter />
    </Layout>
  )
}

export default SentimentPage