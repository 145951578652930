import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../scss/SentimentPageText9.scss";
import leftArrow from './left.png';
import rightArrow from './right.png';
import { Helmet } from "react-helmet";

const ImageGallery = () => {
  const data = useStaticQuery(graphql`
  query MyQuery277 {
    text: allContentfulTextBlock(filter: { id: { eq: "cfd33150-327a-5d7f-ae7f-4397d17c16a6" } }) {
      edges {
        node {
          bodyText {
            raw
          }
          id
          title
        }
      }
    }
    timelineimage: allContentfulImageBlock(filter: { id: { eq: "9948e86a-66eb-5d9c-868f-143d76d99cd7" } }) {
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
    timelineimage2: allContentfulImageBlock(filter: { id: { eq: "b4d82251-47e5-5aa7-addc-6a048c45fb3e" } }) {
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
  }
  `)
  const { bodyText, title } = data.text.edges[0].node;

  const images = [
    data.timelineimage.edges[0].node.image,
    data.timelineimage2.edges[0].node.image,

  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <>
      <Helmet>
        <h3>The Solution</h3>
      </Helmet>
    <div className="scroll9">
      <div className="textContainer99">
        <p className="titleSentimentText99">{title}</p>
        {bodyText && (
          <div className="bodySentimentText99">
            {documentToReactComponents(JSON.parse(bodyText.raw))}
          </div>
        )}
      </div>
      <div className="scroll-container1">
      <div className="image-wrapper1">
        <img
          src={images[currentImageIndex].image.file.url}
          alt={images[currentImageIndex].altText}
          width="780"
        />
        <div className="button-container1">
          <div className="button-left1">
            <img src={leftArrow} alt="Left" onClick={prevImage} />
          </div>
          <div className="button-right1">
            <img src={rightArrow} alt="Right" onClick={nextImage} />
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default ImageGallery;