import React, { useEffect } from "react";
import "../scss/SentimentPagePic1.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";

export default function SentimentPagePic1() {
  const data = useStaticQuery(graphql`
    query MyQuery259 {
      allContentfulImageBlock(filter: { id: { eq: "d66cd210-04a2-5924-9ca9-a9e010a4850f" } }) {
        edges {
          node {
            image {
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1500, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureSentimentPic1">
      <div data-aos="fade-up" className="boardTextContainerSentimentPic1 fade-in">
        <div className={"displayBoardContainerSentimentPic1"}>
          <div className="displayBoardSentimentPic1"></div>
        </div>
      </div>

      <div className={"textContainerSentimentPic1"}>
        <p className={"titleSentimentPic1"}>
          {data.allContentfulImageBlock.edges[0].node.name}
        </p>
      </div>

      <div className={"imageContainerSentimentPic1"}>
        <img
          data-aos="fade-up"
          className={"bigImage"}
          alt={""}
          key={""}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        />
      </div>
    </div>
  );
}
