import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../scss/SentimentPageText7.scss";
import leftArrow from './left.png';
import rightArrow from './right.png';
import { Helmet } from "react-helmet";

const ImageGallery = () => {
  const data = useStaticQuery(graphql`
    query MyQuery282 {
      text: allContentfulTextBlock(filter: { id: { eq: "e49537eb-6b53-594d-8624-996d2f89bdc0" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            id
            title
          }
        }
      }
      timelineimage: allContentfulImageBlock(filter: { id: { eq: "3b42643a-2659-595d-b018-368c881f8a6b" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
          }
        }
      }
      timelineimage2: allContentfulImageBlock(filter: { id: { eq: "a590a9de-0de0-5fb6-9b35-5ca2856ecea4" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
          }
        }
      }
      timelineimage3: allContentfulImageBlock(filter: { id: { eq: "ce7d9400-0269-5489-956d-0ec2a5ef4624" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
          }
        }
      }
    }
  `)
  const { bodyText, title } = data.text.edges[0].node;

  const images = [
    data.timelineimage.edges[0].node.image,
    data.timelineimage2.edges[0].node.image,
    data.timelineimage3.edges[0].node.image,
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  return (
    <>
      <Helmet>
        <h3>The Solution</h3>
      </Helmet>
    <div className="scroll">
      <div className="textContainer7">
        <p className="titleSentimentText7">{title}</p>
        {bodyText && (
          <div className="bodySentimentText7">
            {documentToReactComponents(JSON.parse(bodyText.raw))}
          </div>
        )}
      </div>
      <div className="scroll-container">
      <div className="image-wrapper">
        <img
          src={images[currentImageIndex].image.file.url}
          alt={images[currentImageIndex].altText}
          width="780"
        />
        <div className="button-container">
          <div className="button-left">
            <img src={leftArrow} alt="Left" onClick={prevImage} />
          </div>
          <div className="button-right">
            <img src={rightArrow} alt="Right" onClick={nextImage} />
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
};

export default ImageGallery;