import React, { useEffect } from "react";
import "../scss/SentimentPageText8.scss";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Aos from "aos";
import "aos/dist/aos.css";
import arrow from './arrow_down.png';
import { Helmet } from "react-helmet";

export default function SentimentPageText8() {
  const data = useStaticQuery(graphql`
    query MyQuery287 {
      text: allContentfulTextBlock(filter: { id: { eq: "d52ea09c-8b1c-55ec-9b75-283fc6106006" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            id
            title
          }
        }
      }
      timelineimage: allContentfulImageBlock(filter: { id: { eq: "7cf1880b-588e-5596-a445-c92f03679e2e" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (data.text.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.text.edges[0].node;

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <h2>Measuring Luxury Customer Checkout and Fulfilment Experience</h2>
        <h3>Luxury Leaders Challenge</h3>
      </Helmet>
    <div className="contentFeatureSentimentText8">
      <p className="subtitleSentimentText8">Measuring Luxury Customer Checkout and Fufillment Experience</p>
      <div className="contentContainer8">
          <div className="imageContainer20">
            <img className="RealRealImage1"
              src={data.timelineimage.edges[0].node.image.image.file.url} 
              alt={data.timelineimage.edges[0].node.image.altText} />
          </div>
          <div className="textContainer8">
            <p className="titleSentimentText8">{title}</p>
            {bodyText && (
            <div className="bodySentimentText8">
              {documentToReactComponents(JSON.parse(bodyText.raw))}
            </div>
          )}
          </div>
          

          <div className={'arrowContainerSentimentText8'}>
            <img
              className={'arrowSentimentText8'}
              src={arrow}
              alt="arrow"
              onClick={handleArrowClick} // Attach the click handler here
            />
        </div>
      </div>
    </div>
    </>
  );
}
