import React, { useEffect } from "react"
import "../scss/SentimentPageText4.scss"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Aos from "aos"
import "aos/dist/aos.css"
import arrow from './arrow_down.png';
import { Helmet } from "react-helmet";

export default function SentimentPageText4() {
  const data = useStaticQuery(graphql`
    query MyQuery284 {
      allContentfulTextBlock(filter: { id: { eq: "7165cd8e-2cdb-5761-a3b8-fe9095a9d385" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            title
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  if (data.allContentfulTextBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.allContentfulTextBlock.edges[0].node

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <h2>Listening to the Customer Drives Business and Investment Intelligence</h2>
      </Helmet>
    <div className="contentFeatureSentimentText4">
      <div className="textContainerSentimentText4">
        <p className="titleSentimentText4">{title}</p>
        {bodyText && (
          <div className="bodySentimentText4">
            {documentToReactComponents(JSON.parse(bodyText.raw))}
          </div>
        )}
        <div className={'arrowContainerSentimentText4'}>
          <img
            className={'arrowSentimentText4'}
            src={arrow}
            alt="arrow"
            onClick={handleArrowClick} // Attach the click handler here
          />
      </div>
      </div>
    </div>
    </>
  )
}
