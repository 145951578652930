import React, { useEffect } from "react";
import "../scss/SentimentPagePic2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";

export default function SentimentPagePic2() {
  const data = useStaticQuery(graphql`
    query MyQuery289 {
      allContentfulImageBlock(filter: { id: { eq: "39dc6c1e-1126-5a0a-99e5-0ff50c1d36ba" } }) {
        edges {
          node {
            image {
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1500, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  return (
    <div className="contentFeatureSentimentPic2">
      <div data-aos="fade-up" className="boardTextContainerSentimentPic2 fade-in">
        <div className={"displayBoardContainerSentimentPic2"}>
          <div className="displayBoardSentimentPic2"></div>
        </div>
      </div>

      <div className={"textContainerSentimentPic2"}>
        <p className={"titleSentimentPic2"}>
          {data.allContentfulImageBlock.edges[0].node.name}
        </p>
      </div>

      <div className={"imageContainerSentimentPic2"}>
        <img
          data-aos="fade-up"
          className={"bigImage"}
          alt={""}
          key={""}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        />
      </div>
    </div>
  );
}
