import React, { useEffect } from "react";
import "../scss/SentimentPageText6.scss";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Aos from "aos";
import "aos/dist/aos.css";
import arrow from './arrow_down.png';
import { Helmet } from "react-helmet";

export default function SentimentPageText6() {
  const data = useStaticQuery(graphql`
    query MyQuery281 {
      text: allContentfulTextBlock(filter: { id: { eq: "5b443d6e-462a-5b13-b492-e301dd06ee23" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            id
            title
          }
        }
      }
      timelineimage: allContentfulImageBlock(filter: { id: { eq: "84ccc38b-4e04-57b3-a7b9-c5eb5da99647" } }) {
        edges {
          node {
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (data.text.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.text.edges[0].node;

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <h2>Protecting Multi Million Dollar Investment in Luxury Resale Leader</h2>
        <h3>Fund Managers Challenge</h3>
      </Helmet>
    <div className="contentFeatureSentimentText6">
      <p className="subtitleSentimentText6">Protecting Multi Million Dollar Investment in Luxury Resale Leader</p>
      <div className="contentContainer66">
          <div className="imageContainer6">
            <img className="RealRealImage"
              src={data.timelineimage.edges[0].node.image.image.file.url} 
              alt={data.timelineimage.edges[0].node.image.altText} />
          </div>
          <div className="textContainer66">
            <p className="titleSentimentText6">{title}</p>
            {bodyText && (
            <div className="bodySentimentText6">
              {documentToReactComponents(JSON.parse(bodyText.raw))}
            </div>
          )}
          </div>
          

          <div className={'arrowContainerSentimentText6'}>
            <img
              className={'arrowSentimentText6'}
              src={arrow}
              alt="arrow"
              onClick={handleArrowClick} // Attach the click handler here
            />
        </div>
      </div>
    </div>
    </>
  );
}
