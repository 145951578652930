import React, { useEffect } from "react"
import "../scss/SentimentPageText1.scss"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Aos from "aos"
import "aos/dist/aos.css"
import arrow from './arrow_down.png';
import { Helmet } from "react-helmet";

export default function SentimentPageText1() {
  const data = useStaticQuery(graphql`
    query MyQuery210 {
      allContentfulTextBlock(filter: { id: { eq: "45f404b3-7549-5f6b-9f11-89042a16f286" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            title
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  if (data.allContentfulTextBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.allContentfulTextBlock.edges[0].node

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <title>Luxury Customer Sentiment Analysis for Investors and Leaders</title>
        <meta name="description" content="Luxury Customer Sentiment Analysis - Fund Managers De-Risk Investment into Luxury Companies and Luxury Leaders Acquire Customers and Enter New Markets" />
        <meta name="keywords" content="Luxury Customer Sentiment Analysis, Sentiment Analysis of Social Media, Data Analysis Methods" />
        <h1>Powering and Protecting Investment Positions and Growth</h1>
      </Helmet>
    <div className="contentFeatureSentimentText1">
      <div className="textContainerSentimentText1">
        <p className="titleSentimentText1">{title}</p>
        {bodyText && (
          <div className="bodySentimentText1">
            {documentToReactComponents(JSON.parse(bodyText.raw))}
          </div>
        )}
        <div className={'arrowContainerSentimentText1'}>
          <img
            className={'arrowSentimentText1'}
            src={arrow}
            alt="arrow"
            onClick={handleArrowClick} // Attach the click handler here
          />
      </div>
      </div>
    </div>
    </>
  )
}
